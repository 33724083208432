<template>
  <div>
    <b-row v-if="SINGLE_EXPERT">
      <b-col md="12">
        <expert-details-card  :expert="SINGLE_EXPERT"></expert-details-card>
      </b-col>
    </b-row>
    <b-row v-if="SINGLE_EXPERT">
      <b-col
          md="12"
          class="item">
        <expert-details-events  :expert="SINGLE_EXPERT"></expert-details-events>
      </b-col>
    </b-row>
    <b-row v-if="SINGLE_EXPERT">
      <b-col md="12">
        <expert-details-videonuggets  :expert="SINGLE_EXPERT"></expert-details-videonuggets>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BAlert,
  BAvatar,
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormDatepicker,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BFormTextarea,
  BLink,
  BMedia,
  BRow,
  BSidebar,
  BToast,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import ExpertDetailsCard from '@/views/experts/expert-single/details/ExpertDetailsCard'
import ExpertDetailsVideonuggets from '@/views/experts/expert-single/details/ExpertDetailsVideonuggets'
import ExpertDetailsEvents from '@/views/experts/expert-single/details/ExpertDetailsEvents'
import {mapActions, mapGetters} from "vuex";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BAlert,
    BToast,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormCheckbox,
    BFormTextarea,
    BCardText,
    BFormDatepicker,
    BFormCheckboxGroup,
    BFormFile,
    BAvatar,
    BMedia,
    BFormSelect,
    ExpertDetailsCard,
    ExpertDetailsVideonuggets,
    ExpertDetailsEvents,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      singleExpert: null,
      loaded: true,
    }
  },
  methods: {
    ...mapActions(['GET_SINGLE_EXPERT']),

  },
  mounted() {
    this.GET_SINGLE_EXPERT(this.$route.params.expertid).then(()=>{
      this.loaded = false
    })
  },
  computed:{
    ...mapGetters(['SINGLE_EXPERT'])
  }
}
</script>
<style>
</style>
